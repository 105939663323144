/** Название приложения; отличается от того, что в файле перевода тем, что доступно до инициализации системы и используется в этот момент */
export const APP_NAME = process.env.REACT_APP_APP_NAME || '';

/** Версия приложения в зависимости от окружения (дев, бета, прод) */
export const ENV_VERSION = process.env.NODE_ENV;

// Auth
export const RESEND_VERIFICATION_CODE_TIMEOUT_SECONDS = 60;
export const OTP_LIFETIME_STORAGE_VAR_NAME = 'otpISCreationTime';
export const OPT_LENGTH = 6;
export const PASSWORD_MIN_LENGTH = 5;
export const PASSWORD_MAX_LENGTH = 30;
export const PASSWORD_REGEXP = '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d@$!%*#?&^;()\\\\/]+$';
export const CSRF_COOKIE_NAME = 'XSRF-TOKEN';
export const CSRF_HEADER_NAME = 'X-XSRF-TOKEN';

// Permissions
export const COMMENT_MAX_LIVE_MS = 5 * 24 * 60 * 60 * 1000; // 5 days

// Acquiring integration
export const ACQUIRING_FORM_ID = 'pay-form';

/** TODO текущая версия Yup пропускает кириллицу в email. Удалить и вернуть стандартный метод Yup.string().email() после обновления библиотеки 
 * @deprecated
*/
export const EMAIL_VALIDATION_REGEXP =
  /^(([^<>()[\]\\.,;:а-яА-ЯёЁ\s@"]+(\.[^<>()[\]\\.,;:а-яА-ЯёЁ\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Comments
export const MAX_DRAFTS = 5;
export const KEY_FOR_COMMENT_DRAFT_IN_LOCALSTORAGE = 'commentDrafts';

// Invoice
export const TECHNICIANS_FOR_AUTO_RECALCULATION = 2;
